<template>
  <div class="bg-white h-100 d-flex flex-column align-items-center py-6 pr-11 pl-12">
    <p
      v-if="flowStep && flowStepCount"
      class="position-absolute position-top-0 position-left-0 ml-11 mt-7 mb-0 px-1 font-small-2 text-secondary text-darken-3"
    >
      {{ flowStep }} of {{ flowStepCount }}
    </p>
    <b-img
      :src="appLogoImage"
      alt="logo"
      class="mb-14"
      style="width:72px;"
    />

    <div
      class="d-flex w-100 flex-column justify-content-between mx-auto"
      style="max-width: 860px"
    >
      <div class="mt-2 d-flex flex-row justify-content-between">
        <div
          class="d-flex w-100 flex-column"
          style="max-width: 206px; min-height: 488px;"
        >
          <h3 class="mb-2 h3 fw-bold tw-text-blue-800">
            Select a payment method
          </h3>
          <p class="mb-8 font-small-3 text-secondary text-darken-3">
            Choose a payment method to start enjoying our features.
          </p>
          <b-img
            fluid
            :src="`${mediaUrl}/business_person_illustration@2x.png`"
            alt="Payment selection illustration"
            style="width:176px;"
          />
        </div>
        <div
          class="d-flex w-100 flex-column tw-items-start"
          style="max-width: 506px"
        >
          <div
            class="mb-8 border border-1 border-secondary border-darken-1 px-5 pt-4 pb-1"
            style="border-radius:8px"
          >
            <p class="font-small-3 text-secondary text-accent-2 ml-3 mb-0">
              Select payment method:
            </p>
            <PaymentMethodSelection
              :plan="plan"
              :period="period"
              :licences="licences"
              :currency="currency"
              :currency-symbol="currencySymbol"
              :namespace="namespace"
              :tiered="tiered"
              :stripe="stripe"
              @payment-method-change="handlePaymentMethodChange"
              @event-change="e => eventComplete = e"
            />
          </div>

          <div class="mb-3 d-flex w-100 flex-row justify-content-between">
            <p class="mb-0 font-small-3 fw-normal text-secondary text-darken-3">
              Total without discount
            </p>
            <p
              v-if="!waitForQuote"
              class="mb-0 font-small-3 fw-normal text-secondary text-darken-3"
            >
              {{ subTotalWihtoutDiscount.toLocaleString('en-US') }} {{ currencySymbol }}
            </p>
          </div>
          <div class="mb-3 d-flex w-100 flex-row justify-content-between">
            <p class="mb-0 font-small-3 fw-normal text-secondary text-darken-3">
              Yearly plans discount -{{ yearlyDiscountDisplayValue }}%
            </p>
            <p
              v-if="!waitForQuote"
              class="mb-0 font-small-3 fw-normal text-secondary text-darken-3"
            >
              - {{ yearlyPayDiscount.toLocaleString('en-US') }} {{ currencySymbol }}
            </p>
          </div>
          <div
            v-if="couponDiscount"
            class="mb-3 d-flex w-100 flex-row justify-content-between"
          >
            <p class="mb-0 font-small-3 fw-normal text-secondary text-darken-3">
              Coupon discount
            </p>
            <p
              v-if="!waitForQuote"
              class="mb-0 font-small-3 fw-normal text-secondary text-darken-3"
            >
              - {{ couponDiscount.toLocaleString('en-US') }} {{ currencySymbol }}
            </p>
          </div>
          <div class="mb-3 d-flex w-100 flex-row justify-content-between">
            <p class="mb-0 font-small-3 fw-normal text-secondary text-darken-3">
              VAT {{ quote.vatPercent }}%
            </p>
            <p
              v-if="!waitForQuote"
              class="mb-0 font-small-3 fw-normal text-secondary text-darken-3"
            >
              {{ vatAmount.toLocaleString('en-US') }} {{ currencySymbol }}
            </p>
          </div>
          <div class="d-flex w-100 flex-row align-items-center justify-content-between">
            <p
              class="mb-0 tw-text-blue-800"
              style="font-weight: 500; padding-top: 3px; padding-bottom: 3px;"
            >
              Total for {{ licences }} licences, {{ planName }}:
            </p>
            <p
              v-if="!waitForQuote"
              class="mb-0 h3 tw-text-blue-800"
              style="font-weight: 500"
            >
              {{ total.toLocaleString('en-US') }} {{ currencySymbol }}
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div>
          <a
            class="font-small-3 text-secondary text-darken-4"
            :href="intercomUrl"
            target="_blank"
            @click="trackGoToHelpCenter"
          >
            Go to Help Center
          </a>
        </div>
        <div class="d-flex flex-row align-items-center">
          <b-button
            variant="primary"
            :disabled="waitForPayment || waitForQuote || !eventComplete"
            @click="pay"
          >
            <span
              class="w-100 d-flex align-items-center"
              style="gap:10px"
            >
              Check out
              <b-spinner
                v-if="waitForQuote || waitForPayment"
                small
              />
              <feather-icon
                v-else
                icon="CreditCardIcon"
                size="20"
              />
            </span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethodSelection from '@/views/billing/components/PaymentMethodSelection.vue'
import { BImg, BButton, BSpinner } from 'bootstrap-vue'
import { mapState } from 'vuex'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import constants from '@/constants'
import { trackEvent } from '@core/utils/tracking'

const { authHeaders } = require('../../utils')

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)

export default {
  name: 'BillingPayment',
  components: {
    PaymentMethodSelection,
    BImg,
    BButton,
    BSpinner,
  },
  data() {
    const { appLogoImage } = $themeConfig.app
    return {
      pricing: constants.PRICING,
      appLogoImage,
      stripe,
      eventComplete: false,
      yearlyDiscount: 0.3,
      mediaUrl: process.env.VUE_APP_MEDIA_URL ?? '',
      intercomUrl: process.env.VUE_APP_INTERCOM_URL ?? 'https://intercom.help/surfe/en/',
      quote: {},
      waitForBillingDetails: false,
      waitForQuote: true,
      waitForPayment: false,
      paymentMethod: {},
    }
  },
  computed: {
    ...mapState({
      customer: state => state.api.customer,
      account: state => state.api.account,
    }),
    plan() {
      return this.$route.query.plan || ''
    },
    period() {
      return this.$route.query.period || ''
    },
    flowStep() {
      return this.$route.query.step
    },
    flowStepCount() {
      return this.$route.query.stepCount
    },
    licences() {
      const { licences } = this.$route.query
      return Number.isNaN(licences) ? 0 : Number.parseInt(licences)
    },
    currency() {
      return `${this.$route.query.currency}`.toLowerCase() || 'eur'
    },
    currencySymbol() {
      switch (this.currency) {
        case 'usd':
          return '$'
        case 'eur':
          return '€'
        default:
          return '€'
      }
    },
    planName() {
      switch (this.plan) {
        case 'essential':
          return 'Essential Plan'
        case 'pro':
          return 'Pro Plan'
        case 'basic':
          return 'Basic Plan'
        case 'starter':
          return 'Basic Plan'
        case 'enrichment':
          return 'Enrichment Plan'
        case 'professional':
          return 'Professional Plan'
        case 'enterprise':
          return 'Business Plan'
        case 'business':
          return 'Business Plan'
        case 'free':
          return 'Free'
        case 'free_trial':
          return 'Free Trial'
        default:
          return 'Basic Plan'
      }
    },
    yearlyDiscountDisplayValue() {
      return this.pricing[this.plan][this.currency].yearly.discountDisplayValue
    },
    monthlyPricePerYear() {
      return this.pricing[this.plan][this.currency].monthly * 12 * this.licences
    },
    subTotal() {
      return this.licences * this.quote.unitCost
    },
    couponDiscount() {
      return this.subTotal * (this.quote.couponPercentOff / 100.0)
    },
    subTotalWihtoutDiscount() {
      return this.period === 'yearly' ? this.monthlyPricePerYear : this.subTotal
    },
    subTotalWithDiscount() {
      return this.subTotal - this.couponDiscount
    },
    vatAmount() {
      return (this.subTotalWithDiscount || this.subTotal) * (this.quote.vatPercent / 100.0)
    },
    total() {
      return (this.subTotalWithDiscount || this.subTotal) + this.vatAmount
    },
    yearlyPayDiscount() {
      return this.period === 'yearly' ? this.monthlyPricePerYear - this.subTotal : 0
    },
  },
  created() {
    const { plan, period, licences } = this.$route.query
    if (!plan || !period || !licences) {
      return this.$router.push({ name: 'login' })
    }

    const intercomDialog = document.querySelector('.intercom-lightweight-app')
    if (intercomDialog) intercomDialog.classList.add('d-none')
  },
  async mounted() {
    await this.computeQuote()
  },
  beforeDestroy() {
    const intercomDialog = document.querySelector('.intercom-lightweight-app')
    if (intercomDialog) intercomDialog.classList.remove('d-none')
  },
  methods: {
    async trackGoToHelpCenter() {
      await trackEvent({ type: 'billing-screen_4-help_center_clicked' })
    },
    async computeQuote() {
      this.waitForQuote = true

      // Country
      let country = (this.customer.address || {}).country || 'US'
      if ((this.paymentMethod || {}).card && this.paymentMethod.card.country) {
        country = this.paymentMethod.card.country
      }
      if ((this.paymentMethod || {}).sepa_debit && this.paymentMethod.sepa_debit.country) {
        country = this.paymentMethod.sepa_debit.country
      }

      // Retrieve quote information
      const { data } = await axios.post(
        `${process.env.VUE_APP_CORE_API_URL}/admin/checkout/quote`,
        {
          Plan: this.plan,
          Frequency: this.period,
          Currency: this.currency,
          Quantity: parseInt(this.licences),
          Country: country,
          Namespace: this.account.namespace || 'surfe',
          Tiered: this.account.tiered || false,
        },
        authHeaders(),
      )
      this.quote = data
      this.waitForQuote = false
    },
    async createCardPaymentMethod(card) {
      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: this.customer.name || this.account.name,
        },
      })
      if (result.error) throw result.error
      return result.paymentMethod
    },
    async createSepaPaymentMethod(sepa) {
      const result = await this.stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: sepa,
        billing_details: {
          name: this.customer.name || this.account.name,
          email: this.customer.email || this.account.email, // email is required for SEPA transfers
        },
      })
      if (result.error) throw result.error
      return result.paymentMethod
    },
    async handlePaymentMethodChange({ method, value }) {
      switch (method) {
        case 'card':
          this.paymentMethod = await this.createCardPaymentMethod(value)
          await this.computeQuote()
          break
        case 'sepa':
          this.paymentMethod = await this.createSepaPaymentMethod(value)
          await this.computeQuote()
          break
        default:
          break
      }
    },
    async createSubscription(id, pCountry, type) {
      // Country
      let country = (this.customer.address || {}).country || 'US'
      if (country) country = pCountry

      const { data } = await axios.post(
        `${process.env.VUE_APP_CORE_API_URL}/admin/checkout/subscription`,
        {
          Plan: this.plan,
          Frequency: this.period,
          Currency: this.currency,
          Quantity: parseInt(this.licences),
          Country: country,
          PaymentMethodID: id,
          PaymentMethod: type,
          Namespace: this.account.namespace || 'surfe',
          Tiered: this.account.tiered || false,
        },
        authHeaders(),
      )
      return data
    },
    async handleCardPaymentThatRequiresCustomerAction(subscription, paymentMethodId) {
      const paymentIntent = subscription.latest_invoice.payment_intent
      if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_payment_method') {
        const result = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        if (result.error) throw result
      }
    },
    async handleSepaPaymentThatRequiresCustomerAction(subscription, paymentMethodId) {
      const paymentIntent = subscription.latest_invoice.payment_intent
      if (paymentIntent.status === 'requires_action'
          || paymentIntent.status === 'requires_payment_method'
          || paymentIntent.status === 'requires_confirmation') {
        const result = await this.stripe.confirmSepaDebitPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        if (result.error) throw result
      }
    },
    async pay() {
      this.waitForPayment = true

      try {
        const country = this.paymentMethod?.card?.country ?? this.paymentMethod?.sepa_debit?.country
        const subscription = await this.createSubscription(this.paymentMethod.id, country, this.paymentMethod.type)

        // Some payment methods require a customer to do additional
        // authentication with their financial institution. Eg: 2FA for cards.
        if (!subscription || subscription.status !== 'active') {
          if (this.paymentMethod.type === 'card') await this.handleCardPaymentThatRequiresCustomerAction(subscription, this.paymentMethod.id)
          else await this.handleSepaPaymentThatRequiresCustomerAction(subscription, this.paymentMethod.id)
        }

        await trackEvent({
          type: 'dashboard-billing-confirmed',
          props: { plan: this.plan?.toUpperCase(), count: this.licences, isYearly: this.period === 'yearly' },
        })
        window.postMessage('surfe-payment-finished', '*')
        setTimeout(window.close, 100)
      } catch (err) {
        let msg = err
        if (err.response && err.response.data && err.response.data.message) {
          msg = err.response.data.message
        } else if (err.message) {
          msg = err.message
        } else if (err.error && err.error.message) {
          msg = err.error.message
        } else {
          console.error(`[Stripe] ${JSON.stringify(err)}`)
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to complete payment',
            text: msg,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        }, { timeout: false })
        await trackEvent({ type: 'billing-screen_4-error_viewed' })
      }
      this.waitForPayment = false
    },
  },
}
</script>
